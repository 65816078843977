<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="zu_tree" v-show="tree_zhanshi">
        <div class="zu_tree_con">
            <b class="zu_tree_tit">
                {{title}}
            </b>
            <div class="zu_tree_box">
                <el-tree :data="neirong_list" ref="jiegou_tree"   node-key="id" @check-change="xuanze" :check-strictly='true' 
                :default-checked-keys="yixuan_id" default-expand-all show-checkbox :props="defaultProps"></el-tree>
            </div>
            <div class="foote">
                <p class="queding" @click="tree_true">确定</p>
                <p class="quxiao" @click="tree_false">取消</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'tree',
  data () {
    return {
      tree_zhanshi: false, //
      title: '', // 标题
      neirong_list: [], // 树形图
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      yixuan_id: [],
      dan_duoxuan: '', // 判断是单选还是多选
      guanlian: ''// 判断是否有关联
    }
  },
  mounted () {
  },
  created () {
         
         
  },
  watch: {

  },
  methods: {


    jichu (date, name, yixuan, dan_duoxuan, guanlian) {
      this.tree_zhanshi = true
      this.yixuan_id = yixuan.length != 0 ? yixuan.map(item => item.id) : []
      console.log(this.yixuan_id)
      this.neirong_list = date
      this.title = name
      this.dan_duoxuan = dan_duoxuan
      this.guanlian = guanlian
    },
    tree_true () {
      const date = this.$refs.jiegou_tree.getCheckedNodes()
      date.forEach(item => {
        this.yixuan_id.forEach(ite => {
          if (item.id == ite) {
            this.$set(item, 'yiyou', true)
          }
        })
      })
      console.log(date,'树树树树树树树树树树树树树树树树树树树树树树树树树树树树树树树树树')
      this.$emit('shuxing', date)
      this.tree_zhanshi = false
      this.neirong_list = []
      this.title = ''
    },
    tree_false () {
      this.tree_zhanshi = false
      this.neirong_list = []
      this.title = ''
    },
    xuanze (data, checked, node) {
      console.log(data,'.............................');
      setTimeout(() => {
            if (this.dan_duoxuan == '单选') {
        if (checked === true) {
          this.checkedId = data.id
                 this.$nextTick(() => {
                   
                   console.log(1)
         this.$refs.jiegou_tree.setCheckedKeys([data.id])
      })  
       
        } else {
          if (this.checkedId == data.id) {
             this.$nextTick(() => {
                 
                   console.log(1)
         this.$refs.jiegou_tree.setCheckedKeys([data.id])
      })  
           
          }
        }
      }
      }, 0);
    
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.zu_tree{
    position: fixed;
    left: 0;
    top:0;
    right:0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 99999999999999;
    .zu_tree_con{
        height:680px;
        width:1000px;
        background:#fff;
        position: relative;
        top:142px;
        left:50%;
        margin-left: -500px;
        border-radius: 4px;
        margin-bottom: 140px;
        .zu_tree_tit{
            font-size: 0.16rem;
            color:#1A2533;
            display: block;
            text-align: center;
            padding: 30px 0;
        }
        .zu_tree_box{
            height:450px;
            width:800px;
            overflow-y:auto;
            margin: 0 auto;
            &::-webkit-scrollbar {
                width: 0.2rem;
                height: 0.08rem;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }
            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
        }
        .foote{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 21px 0 40px 0;
            p{
                margin: 0 23px;
                height:43px;
                width:136px;
                border-radius: 2px;
                font-size: 14px;
                line-height: 43px;
                text-align: center;
                font-weight: bold;
                cursor:pointer;
            }
            .queding{
                color:#fff;
                background: #FD3B4B;
            }
            .quxiao{
                color: #888888;
                border:1px solid #888888;
                background: #fff;
            }
        }
    }
}
</style>
