<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="anaproduct">
    <p class="anaproduct_tit">采购规则</p>
    <div class="anaproduct_xuanze">
      <span>采购菜品选择：</span>
      <div class="ana-flex">
        <el-tooltip effect="dark" content="只能选择低于最小库存的菜品进行采购" placement="top-start">
          <span :class="0==caigou_chanpin_xuanze?'bei':''" @click="caigou_chanpin_xuanze=0" class="sspan">
            <img :src="0==caigou_chanpin_xuanze?require('../../../assets/img/xuan_zi.png'):require('../../../assets/img/ccc_quan.png')">
            <span>低于最小库存</span>
          </span>
        </el-tooltip>
        <span :class="1==caigou_chanpin_xuanze?'bei':''" @click="caigou_chanpin_xuanze=1" class="sspan">
          <img :src="1==caigou_chanpin_xuanze?require('../../../assets/img/xuan_zi.png'):require('../../../assets/img/ccc_quan.png')">
          <span>无</span>
        </span>
      </div>
    </div>
    <div class="anaproduct_xuanze">
      <span>采购数量限制：</span>
      <div class="ana-flex">
        <el-tooltip effect="dark" content="每种菜品采购后的预计库存不能超过设置好的最大库存" placement="bottom-start">ll
          <span :class="0==caigou_shuliang_xuanze?'bei':''" @click="caigou_shuliang_xuanze=0" class="sspan">
            <img :src="0==caigou_shuliang_xuanze?require('../../../assets/img/xuan_zi.png'):require('../../../assets/img/ccc_quan.png')">
            <span>采购数量区间</span>
          </span>
        </el-tooltip>
        <span :class="1==caigou_shuliang_xuanze?'bei':''" @click="caigou_shuliang_xuanze=1" class="sspan">
          <img :src="1==caigou_shuliang_xuanze?require('../../../assets/img/xuan_zi.png'):require('../../../assets/img/ccc_quan.png')">
          <span>无</span>
        </span>
      </div>
    </div>
    <p class="anaproduct_tit">库存规则</p>
    <p class="anaproduct_kucun_xuanze">
      <span>采购单库存计算规则：</span>
    <ul>
      <li v-for="(i,index) in caigou_kucun_list" :class="i.id==caigou_kucun_xuanze?'bei':''" :key="i.id" @click="caigou_kucun_xuanze=index">
        <img :src="i.id==caigou_kucun_xuanze?require('../../../assets/img/xuan_zi.png'):require('../../../assets/img/ccc_quan.png')">
        <span>{{i.name}}</span>
      </li>
    </ul>
    </p>
    <p class="anaproduct_tit">菜品库存区间设置</p>
    <p class="anaproduct_qujian_xuanze">
      <!-- <el-radio-group v-model="caigou_qujian_xuanze">
    <el-radio :label="'0'">仓库</el-radio>
    <el-radio :label="'1'">菜品</el-radio>
  </el-radio-group> -->
      <span v-for="(i,index) in caigou_qujian_list" :class="i.id==caigou_qujian_xuanze?'bei':''" :key="i.id" @click="caigou_qujian_xuanze=index">
        <img :src="i.id==caigou_qujian_xuanze?require('../../../assets/img/xuan_zi.png'):require('../../../assets/img/ccc_quan.png')">
        {{i.name}}
      </span>
    </p>
    <!-- :cell-style="cellStyle_tiao"
            :header-cell-style="headerCellStyle" -->
    <div class="bot-end">
      <div class="tablebox">
        <el-table :data="neirong_list" border max-height="600" class="table_con" :header-cell-style="headerCellStyle">
          <el-table-column v-for="(i, ind) in biaotou" :key="ind" :prop="i.con" :label="i.name">
            <template slot-scope="props">
              <div class="anaproduct_biaoge" v-if="props.row.label">
                <p v-if="i.con=='label'&&props.row[i.con]&&props.row[i.con].length!=0&&props.row[i.con]&&props.row[i.con].indexOf('增加')==-1">
                  {{props.row[i.con]}}
                </p>
                <input type="number" v-if="(i.con=='min'||i.con=='max')&&props.row.label.indexOf('增加')==-1&&i.con!='caozuo'" @keyup="xianzhi(i.con,props.row)"
                  v-model="props.row[i.con]" :placeholder="i.con=='min'?'数字，不小于0':'大于最小库存'">

                <span class="tianjia_hang" @click="dianji_zengjia" v-if="props.row[i.con]=='增加仓库'||props.row[i.con]=='增加菜品'">
                  {{caigou_qujian_xuanze==0?'增加仓库':'增加菜品'}}
                </span>

                <span class="shanchu_hang" @click="handledel(props)"
                  v-if="i.con=='caozuo'&&props.row.label&&props.row.label.indexOf('增加')==-1&&neirong_list.length>0">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </template>
          </el-table-column>

        </el-table>
        <p>
          <span class="tianjia_hangs" @click="dianji_zengjia">
            {{caigou_qujian_xuanze=='0'?'增加仓库':'增加菜品'}}
          </span>
        </p>

      </div>
      <p class="btn_sure" @click="dianji_baocun">保存</p>
    </div>

    <zujianDuoxuan ref="cangku" @shuxing='chuanzhi'></zujianDuoxuan>
  </div>
</template>

<script>
import { query_ent_site_tree, query_rule_stock_qty_list, query_rule_purchase_detail, query_rule_stock_detail, query_prod_tree_sale_rule, insert_rule_stock, insert_rule_stock_qty, insert_rule_purchase } from '../../../api/store'

import zujianDuoxuan from './Tree.vue'
export default {
  name: 'anaproduct',
  data () {
    return {

      caigou_shuliang_list: [ // 采购数量限制
        {
          name: '采购数量区间',
          id: '0'
        },
        {
          name: '无',
          id: '1'
        }
      ],
      caigou_kucun_list: [ // 采购单库存计算规则
        {
          name: '按照已审核的出库入库单计算库存',
          id: '0'
        },
        {
          name: '按照已审核加未审核的出库入库单计算库存',
          id: '1'
        }
      ],
      caigou_qujian_list: [ // 菜品库存区间设置
        {
          name: '仓库',
          id: '0'
        },
        {
          name: '菜品',
          id: '1'
        }
      ],
      caigou_chanpin_xuanze: '1', // 采购菜品选择
      caigou_shuliang_xuanze: '1', // 采购数量限制选择
      caigou_kucun_xuanze: '0', // 采购单库存计算规则
      caigou_qujian_xuanze: '0', // 菜品库存区间设置选择
      biaotou: [],
      neirong_list: [],
      chushi_ziduan: '',
      bole: false,
      zhan: [],// 专门存放所选id   每保存为一占
      saveAllInfo: []
    }
  },
  mounted () {
  },
  components: { zujianDuoxuan },
  created () {
    this.jichu()
    this.qiehuan_biaotou(this.caigou_qujian_xuanze)
    this.qiehuan_neirong(this.caigou_qujian_xuanze)
    query_prod_tree_sale_rule({
      data: {
        ent_id: this.$store.state.ent_id
      }
    }).then(res => {
      console.log(res)
      let arr = res.body.data
      let arr2 = []
      for (const item of arr) {
        arr2[item.id] = item
      }
      this.saveAllInfo = arr2
      console.log(arr2)


    })
  },
  watch: {
    caigou_qujian_xuanze () {
      this.qiehuan_biaotou(this.caigou_qujian_xuanze)
      this.qiehuan_neirong(this.caigou_qujian_xuanze)
    }
  },
  methods: {
    getAllInfo () {


    },
    handledel (props) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.neirong_list.splice(props.$index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    jichu () {
      query_rule_stock_detail({
        data: {
          ent_id: this.$store.state.ent_id
        }
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          const date = JSON.parse(JSON.stringify(res.body.data))
          console.log(date)
          for (const i in date) {
            if (date[i].id[0] == 'L') {
              date[i].label = this.saveAllInfo[date[i].id].label
            }
          }
          this.caigou_qujian_xuanze = date != null && date.stock_type ? Number(date.stock_type) - 1 : '0' // 菜品库存区间设置   1仓库  2菜品
          this.chushi_ziduan = date != null && date.stock_type ? Number(date.stock_type) - 1 : '0' // 菜品库存区间设置   1仓库  2菜品
          this.caigou_kucun_xuanze = date != null && date.purchase_stock_cal_type ? Number(date.purchase_stock_cal_type) - 1 : '0' // 采购单库存计算  1按照以审核的出库入库单计算库存  2按照已审核家未审核的出库入库单计算库存
          this.qiehuan_biaotou(this.caigou_qujian_xuanze)
          this.qiehuan_neirong(this.caigou_qujian_xuanze)
        } else if (res.code == 500) { }
      })
      query_rule_purchase_detail({
        data: {
          ent_id: this.$store.state.ent_id
        }
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          const date = JSON.parse(JSON.stringify(res.body.data))
          this.caigou_chanpin_xuanze = date != null && date.prod_select_type != undefined ? (date.prod_select_type == 1 ? '0' : '1') : '0'
          this.caigou_shuliang_xuanze = date != null && date.qty_limit_type != undefined ? (date.qty_limit_type == 1 ? '0' : '1') : '0'
        } else if (res.code == 500) { }
      })
    },
    qiehuan_biaotou (a) {
      let biaotou = []
      if (a == 0) {
        biaotou = [
          {
            name: '仓库',
            con: 'label'
          },
          {
            name: '最小库存',
            con: 'min'
          },
          {
            name: '最大库存',
            con: 'max'
          },
          {
            name: '操作',
            con: 'caozuo'
          }
        ]
      } else {
        biaotou = [
          {
            name: '菜品',
            con: 'label'
          },
          {
            name: '最小库存',
            con: 'min'
          },
          {
            name: '最大库存',
            con: 'max'
          },
          {
            name: '操作',
            con: 'caozuo'
          }
        ]
      }
      this.biaotou = biaotou
    },
    qiehuan_neirong (a) {
      query_rule_stock_qty_list({
        data: {
          ent_id: this.$store.state.ent_id,
          rule_type: a == 0 ? '1' : '2' // 1仓库  2菜品
        }
      }).then(res => {
        console.log(res)
        //
        if (res.code == 200) {
          const date = JSON.parse(JSON.stringify(res.body.data))
          console.log(date)
          let list = []
          if (date) {
            date.forEach(item => {
              //
              if (a == 0) {
                this.$set(item, 'label', item.site_name)

              } else {
                if (item.prod_id[0] == 'L') {
                  this.$set(item, 'label', a == 0 ? item.site_name : this.saveAllInfo[item.prod_id].label)
                } else {
                  this.$set(item, 'label', item.prod_name)
                }
              }


              this.$set(item, 'id', this.caigou_qujian_xuanze == 0 ? item.site_id : item.prod_id)
              this.$set(item, 'min', item.stock_num_min != undefined && item.stock_num_min.length != 0 ? item.stock_num_min : '')
              this.$set(item, 'max', item.stock_num_max != undefined && item.stock_num_max.length != 0 ? item.stock_num_max : '')
              this.$set(item, 'caozuo', '')
            })
            list = date

          }
          // if (list[list.length - 1]&&list[list.length - 1].id&&list[list.length - 1].id != 'zengjia') {

          // } else {
          //   list.push(
          //     {
          //       label: this.caigou_qujian_xuanze == 0 ? '增加仓库' : '增加菜品',
          //       min: '',
          //       max: '',
          //       caozuo: '',
          //       id: 'zengjia'
          //     }
          //   )
          // }

          console.log(list)

          this.neirong_list = list
        } else if (res.code == 500) { }
      })
    },
    xianzhi (text, date) {
      if (text == 'min') {
        this.$set(date, 'min', Number(date.min) <= 0 ? '' : date.min)
      }
    },
    // 表头颜色
    headerCellStyle () { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;'
    },
    chuanzhi (data) {

      const zhi = [] // 选择项里  与  当前列表公共的数据
      const yi_cunzai = [] // 已存在
      data.forEach(item => {
        //
        if (item.children == undefined || (this.caigou_qujian_xuanze == 0 && item.children.length == 0)) {
          if (!item.yiyou) {
            this.$set(item, 'min', '')
            this.$set(item, 'max', '')
            this.$set(item, 'caozuo', '')
            zhi.push(item)
          } else {
            yi_cunzai.push(item)
          }
        } else {
          zhi.push(item)
          // yi_cunzai.push(item)
        }
      })

      this.neirong_list.forEach(item => {
        yi_cunzai.forEach(ite => {
          if (item.id == ite.id) {
            zhi.push(item)
          }
        })
      })

      // zhi.push(
      //   {
      //     label: this.caigou_qujian_xuanze == 0 ? '增加仓库' : '增加菜品',
      //     min: '',
      //     max: '',
      //     caozuo: '',
      //     id: 'zengjia'
      //   }
      // )
      console.log(zhi)

      this.neirong_list = zhi
    },
    dianji_zengjia () {
      console.log(this.neirong_list, this.$refs, '88888888888888888888')

      if (this.caigou_qujian_xuanze == 0) {
        query_ent_site_tree({
          data: {
            ent_id: this.$store.state.ent_id
          }
        }).then(res => {
          console.log(res)
          if (res.code == 200) {
            const date = JSON.parse(JSON.stringify(res.body.data))
            console.log(date)
            this.$refs.cangku.jichu(date, '仓库选择', this.neirong_list, '多选', '有关联')
          } else if (res.code == 500) { }
        })
      } else {
        query_prod_tree_sale_rule({
          data: {
            ent_id: this.$store.state.ent_id
          }
        }).then(res => {
          console.log(res)

          if (res.code == 200) {
            const date = res.body.data
            console.log(date)
            this.$refs.cangku.jichu(date, '菜品选择', this.neirong_list, '多选', '有关联')
          } else if (res.code == 500) { }
        })
      }
    },
    chaijie (data) {
      const list = []
    },
    dianji_baocun () {
      // if (this.neirong_list.length > 1) {
      const list = this.neirong_list

      console.log(list)
      //

      // if (list[list.length - 1].label == '增加菜品') {
      //   list.splice(list.length - 1, 1)
      // }
      // if (list[list.length - 1].label == '增加仓库') {
      //   list.splice(list.length - 1, 1)
      // }


      const zhi = []
      if (this.caigou_qujian_xuanze == 0) {

        list.forEach(item => {

          if (item.id != 'zengjia') {
            zhi.push({
              // site_no:item.site_no,
              site_id: item.id,
              stock_num_min: item.min,
              stock_num_max: item.max
            })
          }
        })
      } else {
        list.forEach(item => {

          if (item.id != 'zengjia') {

            zhi.push({
              prod_id: item.id,
              // prod_code:item.prod_code,
              stock_num_min: item.min,
              stock_num_max: item.max
            })
          }

        })
      }
      console.log(zhi)

      insert_rule_purchase({
        data: {
          ent_id: this.$store.state.ent_id,
          user_id: sessionStorage.getItem('user_id'),
          prod_select_type: this.caigou_chanpin_xuanze == '1' ? '0' : '1', // 采购菜品选择
          qty_limit_type: this.caigou_shuliang_xuanze == '1' ? '0' : '1'// 采购数量限制选择
        }
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          insert_rule_stock({
            data: {
              ent_id: this.$store.state.ent_id,
              user_id: sessionStorage.getItem('user_id'),
              stock_type: this.caigou_qujian_xuanze == 0 ? '1' : '2', // 菜品库存区间设置   1仓库  2菜品
              purchase_stock_cal_type: this.caigou_kucun_xuanze == 0 ? '1' : '2'// 采购单库存计算  1按照以审核的出库入库单计算库存  2按照已审核家未审核的出库入库单计算库存
            }
          }).then(ras => {
            console.log(ras)
            if (ras.code == 200) {
              console.log(this.neirong_list, '|||||||||||||||', zhi, 'gggggggggg');
              //判断库存是否为空



              // if( this.caigou_qujian_xuanze == 0){
              //   zhi.splice(zhi.length-1,1)
              //   this.neirong_list.splice(this.neirong_list.length-1,1)
              // }

              //判断非空
              let b = false
              for (const i in zhi) {
                if (zhi[i].stock_num_max !== '' && zhi[i].stock_num_min !== '') {
                  b = true
                }
                // else{
                //   b = false
                // }
              }
              //判断最大 大于 最小
              let u = true
              for (const i in zhi) {

                let c = Number(zhi[i].stock_num_max);
                let d = Number(zhi[i].stock_num_min)
                    console.log(c,b)
    //
                if (c <= d) {
                  this.$message({
                    message: '请检查库存',
                    type: 'warning'
                  })
                  return
                }

              }
              console.log(zhi)

              console.log(b, u, '56464456465');
              //
              //判断传值列表
              if (this.neirong_list.length > 0) {
                if (b && u) {
                  insert_rule_stock_qty({
                    data: {
                      ent_id: this.$store.state.ent_id,
                      user_id: sessionStorage.getItem('user_id'),
                      list: zhi,
                      stock_type: this.caigou_qujian_xuanze == 0 ? '1' : '2'// 菜品库存区间设置   1仓库  2菜品
                    }
                  }).then(rzs => {
                    if (rzs.code == 200) {

                      this.$message({
                        message: '保存成功',
                        type: 'success'
                      })


                      // if (list[list.length - 1].label == '增加菜品') {
                      //   list.splice(list.length - 1, 1)
                      // }
                      // if (list[list.length - 1].label == '增加仓库') {
                      //   list.splice(list.length - 1, 1)
                      // }







                      this.qiehuan_neirong(this.caigou_qujian_xuanze)

                    } else if (rzs.code == 500) {
                      this.$message({
                        message: '保存失败',
                        type: 'warning'
                      })
                      console.log('673');
                    }
                  })

                }
                else {

                  this.$message({
                    message: '库存不能为空或最大库存不小于最小库存',
                    type: 'warning'
                  })
                  // this.qiehuan_neirong(this.caigou_qujian_xuanze)

                }

              }
              else {
                insert_rule_stock_qty({
                  data: {
                    ent_id: this.$store.state.ent_id,
                    user_id: sessionStorage.getItem('user_id'),
                    list: [],
                    stock_type: this.caigou_qujian_xuanze == 0 ? '1' : '2'// 菜品库存区间设置   1仓库  2菜品
                  }
                }).then(rzs => {

                  if (rzs.code == 200) {
                    this.$message({
                      message: '保存成功',
                      type: 'success'
                    })
                    // if (list[list.length - 1].label == '增加菜品') {
                    //     list.splice(list.length - 1, 1)
                    //   }
                    //   if (list[list.length - 1].label == '增加仓库') {
                    //     list.splice(list.length - 1, 1)
                    //   }
                    this.qiehuan_neirong(this.caigou_qujian_xuanze)
                  } else if (rzs.code == 500) {
                    this.$message({
                      message: '保存失败',
                      type: 'warning'
                    })
                  }
                })
              }

            } else if (ras.code == 500) {
              this.$message({
                message: '保存失败',
                type: 'warning'
              })
            }
          })
        } else if (res.code == 500) {
          this.$message({
            message: '保存失败',
            type: 'warning'
          })
        }
      })

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bot-end {
  width: 880px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}
.tianjia_hangs {
  font-size: 16px;
  display: block;
  width: 120px;
  height: 50px;
  background-color: #fc4353;
  color: white;
  border-radius: 10px;
  line-height: 50px;
  text-align: center;
  margin-top: 10px;
}

.tablebox {
  width: 880px;
  max-height: 230px;
  overflow: hidden;
  overflow-y: auto;
  margin-left: 30px;
  table {
    width: 100%;
    height: 200px;
    color: #333;
    // z-index: 99999999999999999999;
    font-size: 16px;
    th {
      font-size: 16px;
    }
  }
}
.anaproduct {
  background-color: white;
  padding: 10px 10px 50px 10px;
  .ana-flex {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    span {
      display: block;
    }
    .sspan {
      display: flex;
      width: 150px;
      align-items: center;
      span {
        margin-left: 10px;
      }
    }
  }
  // .table_con{
  //   margin-top: 20px;
  //   max-width: 1000px;
  //   margin-left: 30px;
  // }
  .anaproduct_tit {
    font-size: 20px;
    font-weight: 600;
    padding: 2px 10px;
    margin-left: 16px;
    margin-top: 10px;
    border-left: 5px solid red;
  }
  .anaproduct_xuanze {
    display: flex;
    flex-direction: column;
    // align-items: center;
    font-size: 16px;
    margin: 30px 30px;
    span {
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .bei {
      color: #fc4353;
    }
  }
  .anaproduct_kucun_xuanze {
    margin: 30px 30px;
    font-size: 16px;
    span {
      display: block;
    }
    ul {
      display: flex;
      max-width: 700px;
      align-items: center;
      justify-content: space-between;
    }
    li {
      display: flex;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;
      span {
        display: block;
        margin-left: 10px;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
    .bei {
      color: #fc4353;
    }
  }
  .anaproduct_qujian_xuanze {
    display: flex;
    margin: 30px 30px;
    font-size: 16px;
    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin: 0 10px;
      }
    }
    .bei {
      color: #fc4353;
    }
  }
  .anaproduct_biaoge {
    input {
      height: 30px;
      margin: auto;
    }
    .tianjia_hang {
      height: 32px;
      width: 80px;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      color: #fff;
      background: #fc4353;
      display: block;
      border-radius: 3px;
      margin: 0 auto;
      cursor: pointer;
    }
    .shanchu_hang {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 1px solid #ccc;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      i {
        text-align: center;
        line-height: 60px;
        font-size: 20px;
        color: #ccc;
      }
      &:hover {
        border: 1px solid #fc4353;
        i {
          color: #fc4353;
        }
      }
    }
  }
  .btn_sure {
    font-size: 20px;
    height: 50px;
    width: 100px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    background: #fc4353;
    border-radius: 10px;
    margin: 30px auto;
    cursor: pointer;
  }
}
</style>
